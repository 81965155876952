import { useState, useEffect, useMemo } from 'react';
import { ApiRequest } from 'src/api';
import useAuthContext from 'src/hooks/useAuthContext';
import { PATH } from 'src/services/Path';
import { REQUEST_METHOD, ROLE_ID } from 'src/utils/constants/enums';

export const ScreenType = {
  project: 'project',
  shipping: 'shipping',
  operation: 'operation',
  equipment: 'equipment',
};

export const CREATE_ACTION = 'add';
export const EDIT_ACTION = 'modify';
export const DELETE_ACTION = 'delete';
export type ACTION = typeof CREATE_ACTION | typeof EDIT_ACTION | typeof DELETE_ACTION;
export type ScreenTypeKey = keyof typeof ScreenType;
export type ScreenListType = (typeof ScreenType)[ScreenTypeKey];

export type PermissionActionType = {
  project: string[];
  shipping: string[];
  equipment: string[];
  operation: string[];
};

function usePermissionAction(ScreenType?: ScreenListType) {
  const auth = useAuthContext();
  const [permissionAction, setPermissionAction] = useState<PermissionActionType>({
    project: [],
    shipping: [],
    equipment: [],
    operation: [],
  });

  useEffect(() => {
    const fetchPermissionAction = async () => {
      const response = await ApiRequest(`${PATH.ECO_SALES_PERMISSION_ACTION}/${auth.userInfo?.user.id}`, REQUEST_METHOD.GET, true);
      if (response?.data) {
        setPermissionAction({ ...response.data });
      }
    };

    fetchPermissionAction();
  }, []);

  const allowAction = (action: ACTION) => {
    if (ROLE_ID.ADMIN === auth.userInfo?.user?.role_id) {
      return true;
    }
    if (permissionAction && ScreenType) {
      return permissionAction[ScreenType as ScreenTypeKey].includes(action);
    }
    return false;
  };

  const permissionActionData = useMemo(() => permissionAction, [permissionAction]);

  return {
    allowAction,
    permissionActionData,
  };
}

export default usePermissionAction;
