export enum REQUEST_METHOD {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export enum PAGE {
  PAGE_SIZE_10 = 10,
  PAGE_SIZE_15 = 15,
  PAGE_SIZE_20 = 20,
  PAGE_SIZE_25 = 25,
  PAGE_SIZE_30 = 30,
}
export const enum ROLE {
  ADMIN = 7,
  USER = 8,
  PROJECT_MANAGER = 6,
}

export const ROLE_PERMISSION = {
  NEED_CHECK_VISIBILITY: [ROLE.USER, ROLE.PROJECT_MANAGER],
  NEED_CHECK_MODIFY: [ROLE.USER, ROLE.PROJECT_MANAGER],
  NEED_APPROVED: [ROLE.USER, ROLE.PROJECT_MANAGER],
  CAN_APPROVED: [ROLE.ADMIN],
  CAN_SETTING_USER: [ROLE.ADMIN, ROLE.PROJECT_MANAGER],
  CAN_ACTIVE_TAB: [ROLE.ADMIN],
  CAN_MODIFY: [ROLE.ADMIN],
  CAN_SHARE: [ROLE.ADMIN, ROLE.PROJECT_MANAGER],
};

export const DATE_PATTERN = {
  YYYY_MM_DD: 'YYYY/MM/DD',
  YYYY_MM_DD_HH_mm_ss: 'YYYY/MM/DD HH:mm:ss',
  DD_MM_YYYY: 'DD/MM/YYYY',
  YYYY: 'YYYY',
  MM_YYYY: 'MM/YYYY',
};

export const NOTIFY_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

export const PAGINATION = {
  DEFAULT_PAGE: 1,
  DEFAULT_PAGE_SIZE: 10,
  PAGE_SIZE_OPTION: [10, 15, 20, 25, 30],
  DEFAULT_TOTAL_PAGE: 1,
};

export enum IMPACTS_TYPE {
  WASTE = 'Waste',
  WATER_DISPOSAL = 'Water disposal (non domestic activities)',
  WATER_WITHDRAWAL = 'Water withdrawal',
  ENERGY_USE = 'Energy use',
  OIL_AND_CHEMICALS = 'Oil & chemical spills',
  OIL_AND_CHEMICALS_OTHER_CHEMICALS = 'Other chemicals',
  UNIT_M3 = 'm3',
  UNIT_KL = 'kL',
  UNIT_CO2EQ = 'kg CO2e',
  UNIT_MWH = 'MWh',
  KWH = 'kWh',
  HVDC = 'HVDC',
  GPQSS = 'GPQSS',
}
export const DEFAULT_LIMIT_SIZE = 100;
export const MONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export enum TYPE_FILE {
  IMAGE_JPG = 'jpg',
  IMAGE_JPEG = 'jpeg',
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  XLS = 'xls',
  XLS_EXCEL = 'xlsx',
  MS_WORD = 'doc',
  DOCUMENT = 'docx',
  XLSX = 'xlsx',
  CSV = 'csv',
  ZIP = 'zip',
  XLSM = 'xlsm',
}

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export enum TYPE_MODULES {
  EQUIPMENT = 'Equipment',
  SOLUTION = 'Solution',
  MODULES = 'Modules',
}

export const SELECT_TYPE_CALCULATION = {
  OVERALl_LOSSES: 'Overall losses',
  AVERAGE_CHARGING: 'Average charging',
  CHARGING_CYCLES: 'Charging cycles',
};

export const VALUES_ITEM_OPERATION_MODULE_DEFAULT = {
  LOSSES_RATE: 6,
  AVERAGE_LOADING: 46,
};

export enum FONT_SIZE {
  FONT_SIZE_32 = 32,
  FONT_SIZE_28 = 28,
  FONT_SIZE_24 = 24,
}

export const ORGANIZATIONAL = {
  ECO_SALES: 2,
};
export const DATA_FACTOR = [
  {
    key: 0,
    value: 'Global Warming Potential',
    label: 'Global Warming Potential',
  },
  {
    key: 1,
    value: 'Acidification',
    label: 'Acidification',
  },
  {
    key: 2,
    value: 'Eutrophication',
    label: 'Eutrophication',
  },
  {
    key: 3,
    value: 'Freshwater Aquatic Ecotoxicity',
    label: 'Freshwater Aquatic Ecotoxicity',
  },
  {
    key: 4,
    value: 'Marine Aquatic',
    label: 'Marine Aquatic',
  },
  {
    key: 5,
    value: 'Terrestric Ecotoxicity',
    label: 'Terrestric Ecotoxicity',
  },
  {
    key: 6,
    value: 'Human Toxicity',
    label: 'Human Toxicity',
  },

  {
    key: 7,
    value: 'Ozone Layer Depletion',
    label: 'Ozone Layer Depletion',
  },
  {
    key: 8,
    value: 'Photochemical Ozone Creation',
    label: 'Photochemical Ozone Creation',
  },
];

export const ROLE_ID = {
  ADMIN: 7,
  PROJECT_MANAGER: 6,
  USER: 8,
};

export const GAS_LEAKAGE = {
  SF_6: 'SF6',
  ECONIQ_GAS: 'EconiQ gas',
};
export const SCOPE = [
  {
    name: 'Scope 3',
    bg: 'rgb(255, 0, 38)',
  },
  {
    name: 'Scope 2',
    bg: 'rgb(45, 45, 45)',
  },
  {
    name: 'Scope 1',
    bg: 'rgb(189, 190, 191)',
  },
];
export const SCOPE_SORT = ['Scope 3', 'Scope 1', 'Scope 2'];
export const OPERATION_SOURCE = {
  LOCATION: 'location',
  ENERGY: 'source_of_energy',
};

export const TYPE_SOLUTION_PROJECT = {
  ConventionalGISSubstation420kV: 'Conventional GIS substation 420 kV',
  ConventionalGISSubstation14524kV: 'Conventional GIS substation 145/24 kV',
  HdvcGeneration4: 'HVDC – Generation 4',
  HdvcGeneration5: 'HVDC – Generation 5',
  GridEXpandtm145kv: 'Grid-eXpand™ 145/24 kV',
  GridEXpandtm420kv: 'Grid-eXpand™ 420 kV',
  GisSubstaion: 'Conventional GIS substation 420 kV',
  GridEMotion: 'Grid-eMotion ®',
  FixedSeriesCompensation: 'Fixed Series Compensation',
  SFCLight: 'SFC Light',
  SVCLightV2250MVAR: 'SVC Light V2 250 MVAR',
  SVCLightV3150MVAR: 'SVC Light V3 150 MVAR',
  SVCLightV3200MVAR: 'SVC Light V3 200 MVAR',
  SVCLightV3250MVAR: 'SVC Light V3 250 MVAR',
  SVCLightV3300MVAR: 'SVC Light V3 300 MVAR',
};

export const dataWarning = {
  name: 'Alert!!!',
  description: [
    'Before proceeding with the modification of the SVC Light V3 valve 4-cells module, it is important to understand how it has been configured. Each SVC Light V3 valve 4-cells module typically contains three phases, which can be thought of as rows. In a double Y topology, the number of phases is always 6, meaning there are two symmetrical sides opposite each other as shown in the figure above.',
    'On the other hand, we have the modules per phase, which can be considered as columns. SVC Light V3 150 MVAR or 200 MVAR typically contain 3 modules per phase (like columns), while larger SVC Light V3s, such as those of 250 MVAR and 300 MVAR, contain an additional module or column, totaling 4.',
    'Each module always contains 4 cells. To calculate the total number of 4-cells modules in a SVC Light V3 ,you need to multiply the number of modules per phase (3 for 150 and 200 MVAR SVC Light V3 s, and 4 for 250 and 300 MVAR SVC Light V3 s), then multiply by the number of phases (always 3), and finally by the number of sides (2 in a double Y topology). This gives us the total number of 4-cells modules:',
  ],
  typical: ['Typical configuration for 300 MVAR and 250 MVAR', 'Typical configuration for 200 MVAR and 150 MVAR'],
  modules: [
    '(4 { modules/phase} x 3  x { phases} x 2{ sides} = 24 valve 4-cells module)',
    '(3 { modules/phase} x 3  x { phases} x 2{ sides} = 18 valve 4-cells module)',
  ],
};

export const dataSVC = {
  name: 'SVC Light V3 valves',
  description: [
    'Each SVC Light V3 valve 4-cells module typically contains three phases, which can be thought of as rows. In a double Y topology, the number of phases is always 6, meaning there are two symmetrical sides opposite each other as shown in the figure above.',
    'On the other hand, we have the modules per phase, which can be considered as columns. SVC Light V3 150 MVAR or 200 MVAR typically contain 3 modules per phase (like columns), while larger SVC Light V3, such as those of 250 MVAR and 300 MVAR, contain an additional module or column, totaling 4. Each module always contains 4 cells. ',
  ],
  typical: ['Typical configuration for 300 MVAR and 250 MVAR', 'Typical configuration for 200 MVAR and 150 MVAR'],
};

export const dataEquipmentSVCV3 = [239, 400, 443, 486];

export const excludedProjectIds = [9, 12, 13, 14];

export const description = {
  title: 'SVC Light V3',
  content: 'This project offers four types of solutions based on their rating. Please select one option from these:',
  footer: [
    '*This project offers four types of solutions based on their rating. Keep in mind that the number of cells is linked to the solution\u0027s rating, and each solution has a predefined number of cells, although they can be modified.',
  ],
};

export const dataLightSVCV3: any = {
  id: 9,
  description: {
    solution: 'SVC Light V3',
    content:
      'The scope for this LCA is to model SVC Light V3 divided into sub-scopes containing products, components and materials. The FU* for the assessment is the complete life cycle of the substation system.',
    title: 'The sub-scopes of SVC Light V3 are the following:',
    list: ['Auxiliary power system', 'Building & yard', 'Control, protection & panels', 'Main transformer', 'MV bus', 'SVC branch'],
    footer: [
      '*A Functional Unit (FU) is used to relate the result to a fixed factor, enabling the comparison of different cases based on the prerequisites of a certain function.',
    ],
  },
  createdAt: '2024-01-07T18:58:26.953Z',
  updatedAt: '2024-01-07T18:58:26.953Z',
  solution: 'SVC Light V3',
  project_id: 9,
  quantity: 1,
  eco_gpg_master: {
    id: 2,
    gpg: 'GPQSS',
    createdAt: '2024-09-22T23:47:02.447Z',
    updatedAt: '2024-09-22T23:47:02.447Z',
    icon: null,
  },
};

export const dataQuantityLightV3 = {
  QUANTITY_V3_150_200: '18',
  QUANTITY_V3_250_300: '24',
  LIGHT_V3_150: '150',
  LIGHT_V3_200: '200',
  LIGHT_V3_250: '250',
  LIGHT_V3_300: '300',
};
