export enum UNIT {
  UNIT_KGCO = 'kg CO',
  UNIT_TCO = 'tCO',
  UNIT_KG = 'kg',
  UNIT_KM = 'km',
  UNIT_KWH = 'kWh',
  UNIT_EQ = 'eq',
  UNIT_KW = 'kW',
  UNIT_KVAR = 'KVAR',
  UNIT_W = 'W',
  UNIT_MVA = 'MVA',
  UNIT_KWH_YEAR = 'kWh / year',
  UNIT_KW_YEAR = 'kW / year',
  UNIT_YEAR = 'years',
  UNIT_PERCENT = '%',
  UNIT_PERCENT_YEAR = '% / year',
  UNIT_MVAR = 'MVAR',
  UNIT_MW = 'MW',
  UNIT_SO = 'SO',
  UNIT_PHOSPHATE = 'Phosphate',
  UNIT_DCB = 'DCB',
  UNIT_R11 = 'R11',
  UNIT_ETHENE = 'Ethene',
  UNIT_CO = 'CO',
  UNIT_YEAR_POWER = 'year',
}
