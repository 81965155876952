import { ConfigProvider } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';
import ConfigRoutes from './components/layouts/ConfigRoutes';
import Footer from './components/layouts/Footer';
import Header from './components/layouts/Header';
import { AuthContextProvider } from './context/AuthContext';
import GlobalStyles from './styles/GlobalStyles/GlobalStyles';
import { GlobalStateProvider } from './context/GlobalStateContext';
import { Loading } from './components/layouts/Loading';
import './App.css';
import './styles/utils/_index.scss';
import './styles/bases/_index.scss';
import { useGlobalErrorHandler } from './hooks/useErrorGlobalHandler';
import useHandleZoom from './hooks/useHandleZoom';
import React from 'react';
import { ProjectProvider } from './context/ProjectContext';
import { UndoRedoProvider } from './context/UndoRedoContext';

function App() {
  const { fontSize } = useHandleZoom();
  useGlobalErrorHandler();
  return (
    <React.Fragment>
      <Router>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Arial',
              fontSize: fontSize,
            },
          }}
        >
          <GlobalStateProvider>
            <AuthContextProvider>
              <ProjectProvider>
                <UndoRedoProvider>
                  <GlobalStyles>
                    <Loading>
                      <div className="App">
                        <div className="wrapper">
                          <Header />
                          <div className="container">
                            <div className="content">
                              <ConfigRoutes></ConfigRoutes>
                            </div>
                          </div>
                          <Footer />
                        </div>
                      </div>
                    </Loading>
                  </GlobalStyles>
                </UndoRedoProvider>
              </ProjectProvider>
            </AuthContextProvider>
          </GlobalStateProvider>
        </ConfigProvider>
      </Router>
    </React.Fragment>
  );
}

export default App;
